import { NavigateFunction } from 'react-router-dom';
import * as Repository from "../repositories/producto-repository";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { CODE_SERVER_RETURN, UPLOAD_FILEPRODUCTO } from '../utils/contants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export function toNuevo(router: NavigateFunction) {
    router("/main/producto/mantenimiento/nuevo")
}
export function toConsulta(router: NavigateFunction) {
    router("/main/producto/consulta")
}
export async function doConsultar(setListaConsulta: any, FilterEmpresa: string, FilterSubCategoria: string, FilterMarca: string, FilterCategoria: string, FilterNombreProducto: string) {
    var dataResponse = await Repository.getData(FilterEmpresa, FilterSubCategoria, FilterMarca, FilterCategoria, FilterNombreProducto)
    setListaConsulta(dataResponse)
}
export async function toEditar(router: NavigateFunction, row: any) {
    var codeEditar = row.CodeProducto
    router("/main/producto/mantenimiento/" + codeEditar)
}
export async function toQuestionInactivaActiva(row: any, setListaConsulta: any) {
    const MySwal = withReactContent(Swal)
    var question = row.Estado == 1 ? "¿Desea eliminar el registro?" : "¿Desea activar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then((result: any) => {
        if (result.isConfirmed) {
            toInactivaActiva(row, setListaConsulta)
        }
    })
}
async function toInactivaActiva(row: any, setListaConsulta: any) {
    var codeEliminar = row.CodeProducto
    const objSave = {
        CodeProducto: codeEliminar
    }
    if (row.Estado == 1) {
        var dataResponse = await Repository.inactiva(objSave)
    } else if (row.Estado == 0) {
        var dataResponse = await Repository.activa(objSave)
    }
    doConsultar(setListaConsulta, "0", "0", "0", "0", "")
}
export async function getByCode(code: any, setCodeCategoria: any, setListaComboCategoria: any, setCodeSubCategoria: any, setListaComboSubCategoria: any, setCodeMarca: any, setNombreProducto: any, setSkuMaestro: any, setSkuCadena: any, setEan: any, setGramajeTamanio: any, setPvp: any, setUrlImagen: any) {
    var dataResponse = await Repository.getByCode(code)
    if (dataResponse != null) {

        setCodeSubCategoria(dataResponse.CodeSubCategoria)
        setCodeCategoria(dataResponse.CodeCategoria)
        setCodeMarca(dataResponse.CodeMarca)
        setNombreProducto(dataResponse.NombreProducto)
        setSkuMaestro(dataResponse.SkuMaestro)
        setSkuCadena(dataResponse.SkuCadena)
        setEan(dataResponse.Ean)
        setGramajeTamanio(dataResponse.GramajeTamanio)
        setPvp(dataResponse.Pvp)
        setUrlImagen(dataResponse.UrlImagen)
        getListaComboCategoria(dataResponse.CodeMarca, setListaComboCategoria)
        getListaComboSubCategoria(dataResponse.CodeCategoria, setListaComboSubCategoria)

    }
}
export async function getListaProductoTiendaDetalleByCodeProducto(code: any, setListaTiendas: any) {
    var dataResponse = await Repository.getListaProductoTiendaDetalleByCodeProducto(code)
    if (dataResponse != null) {
        setListaTiendas(dataResponse)
    }
}
export async function save(router: NavigateFunction, codeProducto: string, codeMarca: string, codeCategoria: string, codeEmpresa: string, codeSubCategoria: string, nombreProducto: string, skuMaestro: string, skuCadena: string, ean: string, gramajeTamanio: string, pvp: string, file: any, urlImagen: string) {
    console.log(file)
    const MySwal = withReactContent(Swal)
    var question = "¿Desea guardar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then(async (result: any) => {

        if (result.isConfirmed) {

            // Subir Archivo
            var URLFile = urlImagen == null || urlImagen == "" ? null : urlImagen
            if (file != null) {
                const formDataToUpload = new FormData();
                const tipoBucket = UPLOAD_FILEPRODUCTO.USUARIO_FOTO_FOTOPRODUCTO; // Producto
                formDataToUpload.append('FileImage', file);
                formDataToUpload.append('TipoBucket', tipoBucket.toString());
                var dataResponseURL = await Repository.uploadFile(formDataToUpload)
                if (dataResponseURL != null && dataResponseURL.Code === 0) {
                    URLFile = dataResponseURL.Data.PathFile
                }
            }
            console.log("Code a actualizar: " + codeProducto)
            if (codeProducto != null && codeProducto != "") {
                const objSave = {
                    CodeProducto: codeProducto,
                    CodeMarca: codeMarca,
                    CodeCategoria: codeCategoria,
                    CodeEmpresa: codeEmpresa,
                    CodeSubCategoria: codeSubCategoria,
                    NombreProducto: nombreProducto,
                    SkuMaestro: skuMaestro,
                    SkuCadena: skuCadena,
                    Ean: ean,
                    GramajeTamanio: gramajeTamanio,
                    Pvp: pvp,
                    UrlImagen: URLFile
                }
                var dataResponse = await Repository.update(objSave)
                if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    });
                } else if (dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",

                    });

                } else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)
                }


            } else {
                const objSave = {
                    CodeMarca: codeMarca,
                    CodeCategoria: codeCategoria,
                    CodeEmpresa: codeEmpresa,
                    CodeSubCategoria: codeSubCategoria,
                    NombreProducto: nombreProducto,
                    SkuMaestro: skuMaestro,
                    SkuCadena: skuCadena,
                    Ean: ean,
                    GramajeTamanio: gramajeTamanio,
                    Pvp: pvp,
                    UrlImagen: URLFile
                }
                var dataResponse = await Repository.save(objSave)
                if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    });
                } else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)
                }
            }
        }
    })
}
export async function Mensaje() {
    const MySwal = withReactContent(Swal)
    var message = "Tiene que completar los campos"
    MySwal.fire({
        title: <p>{message}</p>,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
    }
    )
}
export async function saveProductoTienda(router: NavigateFunction, codeProducto: string, ListaTiendas: any) {
    var newListaTiendas = ListaTiendas.map((item: any) => {
        var newObj = {
            CodeTienda: item.CodeTienda
        }
        return newObj
    })
    console.log("Code a actualizar: " + codeProducto)
    if (codeProducto != null && codeProducto != "") {
        const objSave = {
            CodeProducto: codeProducto,
            tiendas: newListaTiendas,
        }
        var dataResponse = await Repository.savePT(objSave)
    }
}

export async function getListaComboSubCategoria(codeCategoria: string, setListaComboSubCategoria: any) {
    if (codeCategoria != "-" && codeCategoria != "0") {

        var dataResponse = await Repository.getListaComboSubCategoria(codeCategoria)
        setListaComboSubCategoria(dataResponse)
    } else {
        setListaComboSubCategoria([])
    }
}
export async function getListaComboMarca(setListaComboMarca: any) {
    var dataResponse = await Repository.getListaComboMarca()
    setListaComboMarca(dataResponse)
}
export async function getListaComboCategoria(codeMarca: string, setListaComboCategoria: any) {

    if (codeMarca != "-" && codeMarca != "0") {
        var dataResponse = await Repository.getListaComboCategoria(codeMarca)
        setListaComboCategoria(dataResponse)
    } else {
        setListaComboCategoria(dataResponse)

    }
}
export async function getListaComboCategorias(setListaComboCategorias: any) {
    var dataResponse = await Repository.getListaComboCategorias()
    setListaComboCategorias(dataResponse)
}
export async function getListaComboSubCategorias(setListaComboSubCategorias: any) {
    var dataResponse = await Repository.getListaComboSubCategorias()
    setListaComboSubCategorias(dataResponse)
}
export async function getListaComboTienda(setListaComboTienda: any) {
    var dataResponse = await Repository.getListaComboTienda()
    setListaComboTienda(dataResponse)
}
export function addMember(tiendas: string, ListaComboTiendas: any, ListaTiendas: any, setListaTiendas: any) {

    var NombreTienda = ""
    ListaComboTiendas.forEach((item: any) => {
        if (item.Id == tiendas) {
            NombreTienda = item.Texto
            return
        }
    });
    var existe = false
    ListaTiendas.forEach((item: any) => {
        if (item.CodeTienda == tiendas) {
            existe = true
            return
        }
    });
    console.log("Existe?: " + existe)

    if (!existe) {
        var objMember = {
            CodeTienda: tiendas,
            NombreTienda: NombreTienda
        }
        var newListaTienda = ListaTiendas.map((item: any) => { return item })
        newListaTienda.push(objMember)
        setListaTiendas(newListaTienda)
    } else {
    }
}
export function toQuestionEliminarTiendas(row: any, ListaTiendas: any, setListaTiendas: any) {
    var indice = -1
    ListaTiendas.forEach((item: any, index: number) => {
        if (item.CodeTienda == row.CodeTienda) {
            indice = index
            return
        }
    });

    if (indice > -1) {
        ListaTiendas.splice(indice, 1);
        var newListaTiendas = ListaTiendas.map((item: any) => { return item })
        setListaTiendas(newListaTiendas)
    }
}

export async function doExportar(FilterEmpresa: string, FilterSubCategoria: string, FilterMarca: string, FilterCategoria: string, FilterNombreProducto: string) {
    var dataResponse = await Repository.doExport(FilterEmpresa, FilterSubCategoria, FilterMarca, FilterCategoria, FilterNombreProducto)

}

export async function doCargarPlantillaProducto() {
    var dataResponse = await Repository.doCargaPlantillaProducto()

}

export async function importarProducto(
    router: NavigateFunction,
    fileImportar: any
) {
    if (fileImportar != null) {
        const formDataToUpload = new FormData();
        formDataToUpload.append("FileExcel", fileImportar);

        var dataResponseURL = await Repository.importarProducto(formDataToUpload);
        if (dataResponseURL != null) {
            const MySwal = withReactContent(Swal)

            if (dataResponseURL.Code == CODE_SERVER_RETURN.ERROR) {
                MySwal.fire({
                    title: <p>{dataResponseURL.Message}</p>,
                    icon: "error",
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                });
            } else {
                toast.success('Datos importados satisfactoriamente!')
                toConsulta(router);
            }

        }
    }
}