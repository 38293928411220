
import { NavigateFunction } from 'react-router-dom';
import * as Repository from "../repositories/encuesta-repository";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CODE_SERVER_RETURN } from '../utils/contants';
import { v4 as uuidv4 } from 'uuid';

interface EncuestaDetalle {
    id: string,
    CodeEncuestaDetalle: string,
    CodeRespuesta: string,
    DesEncuesta: string,
    DesOpcionRespuesta: string,
    Detalles?: []
}

export function toNuevo(router: NavigateFunction) {
    router("/main/encuesta/mantenimiento/nuevo")
}
export function toConsulta(router: NavigateFunction) {
    router("/main/encuesta/consulta")
}
export async function doConsultar(setListaConsulta: any, FilterDesEncuesta: string) {
    var dataResponse = await Repository.getData(FilterDesEncuesta)
    setListaConsulta(dataResponse)
}
export async function toEditar(router: NavigateFunction, row: any) {
    var codeEditar = row.CodeEncuesta
    router("/main/encuesta/mantenimiento/" + codeEditar)
}
export async function toQuestionInactivaActiva(row: any, setListaConsulta: any) {
    const MySwal = withReactContent(Swal)
    var question = row.Estado == 1 ? "¿Desea eliminar el registro?" : "¿Desea activar el registro?"

    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"

    }).then((result: any) => {
        if (result.isConfirmed) {
            toInactivaActiva(row, setListaConsulta)
        }
    })
}
async function toInactivaActiva(row: any, setListaConsulta: any) {
    var codeEliminar = row.CodePregunta
    const objSave = {
        CodePregunta: codeEliminar
    }
    if (row.Estado == 1) {
        var dataResponse = await Repository.inactiva(objSave)
    } else if (row.Estado == 0) {
        var dataResponse = await Repository.activa(objSave)
    }
    doConsultar(setListaConsulta, "")
}
export async function getByCode(code: any, setNombreEncuesta: any, setTipoTienda: any, setListaEncuestaDetalle: any, setListaEncuestaFoto: any) {

    var dataResponse = await Repository.getByCode(code)
    if (dataResponse != null) {

        setTipoTienda(dataResponse.IdTipoTienda)
        setNombreEncuesta(dataResponse.NombreEncuesta)

        /*var DataEncuestaDetalle = dataResponse.EncuestaDetalle.map((item: any) => ({
            ...item,
            PorcentajePonderado: item.PorcentajePonderado * 100,
            id: uuidv4(), // Genera un ID único para cada elemento
        }))*/

        const agregarIdNiveles = (elementos: any) =>
            //if (elementos != undefined) {
            elementos.map((elemento: any) => ({
                ...elemento,
                PorcentajePonderado: elemento.PorcentajePonderado * 100,
                id: uuidv4(), Detalles: agregarIdNiveles(elemento.Detalles)
            }))

        var DataEncuestaDetalle = agregarIdNiveles(dataResponse.EncuestaDetalle)

        var DataEncuestaFoto = dataResponse.EncuestaFoto.map((item: any) => ({
            ...item,
            id: uuidv4(), // Genera un ID único para cada elemento
        }))

        console.log(DataEncuestaDetalle)
        setListaEncuestaDetalle(DataEncuestaDetalle)
        setListaEncuestaFoto(DataEncuestaFoto)
    }
}
export async function save(router: NavigateFunction, codeEncuesta: string, nombreEncuesta: string, tipoTienda: number, ListaEncuestaDetalle: any, ListaEncuestaFoto: any) {

    const MySwal = withReactContent(Swal)
    var question = "¿Desea guardar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then(async (result: any) => {
        if (result.isConfirmed) {


            //var orden = 0

            const prepararListaDetalle = (elementos: any, orden: number) =>
                //if (elementos != undefined) {
                elementos.map((elemento: any) => {
                    orden++
                    var newObj = {
                        CodeEncuestaDetalle: elemento.CodeEncuestaDetalle,
                        Orden: orden,
                        DesEncuesta: elemento.DesEncuesta,
                        CodeRespuesta: elemento.CodeRespuesta,
                        DesOpcionRespuesta: elemento.DesOpcionRespuesta,
                        PorcentajePonderado: elemento.PorcentajePonderado > 0 ? elemento.PorcentajePonderado / 100 : 0,
                        Detalles: prepararListaDetalle(elemento.Detalles, 0),
                    }
                    return newObj;
                });




            var newListaEncuestaDetalle = prepararListaDetalle(ListaEncuestaDetalle, 0)

            /*var newListaEncuestaDetalle = ListaEncuestaDetalle.map((item: any) => {
                orden++
                var newObj = {
                    CodeEncuestaDetalle: item.CodeEncuestaDetalle,
                    Orden: orden,
                    DesEncuesta: item.DesEncuesta,
                    CodeRespuesta: item.CodeRespuesta,
                    DesOpcionRespuesta: item.DesOpcionRespuesta,
                }
                return newObj
            })*/

            var orden = 0
            var newListaEncuestaFoto = ListaEncuestaFoto.map((item: any) => {
                orden++
                var newObj = {
                    CodeEncuestaFoto: item.CodeEncuestaFoto,
                    Orden: orden,
                    DesFoto: item.DesFoto
                }
                return newObj
            })

            if (codeEncuesta != null && codeEncuesta != "") {
                const objSave = {
                    CodeEncuesta: codeEncuesta,
                    NombreEncuesta: nombreEncuesta,
                    IdTipoTienda: tipoTienda,
                    EncuestaDetalle: newListaEncuestaDetalle,
                    EncuestaFoto: newListaEncuestaFoto
                }

                var dataResponse = await Repository.update(objSave)

                if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    });
                } else if (dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",

                    });
                } else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)

                }
            } else {
                const objSave = {
                    NombreEncuesta: nombreEncuesta,
                    IdTipoTienda: tipoTienda,
                    EncuestaDetalle: newListaEncuestaDetalle,
                    EncuestaFoto: newListaEncuestaFoto
                }
                var dataResponse = await Repository.save(objSave)
                if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    });
                } else if (dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                } else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)
                }
            }
        }
    })
}

export async function Mensaje() {
    const MySwal = withReactContent(Swal)
    var message = "Tiene que completar los campos"
    MySwal.fire({
        title: <p>{message}</p>,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
    }
    )
}

export async function doExportar(FilterDesEncuesta: string) {
    var dataResponse = await Repository.doExport(FilterDesEncuesta)
}

export function addMember(editingRow: any, codeSeccion: string, codeGrupo: string, codeRespuesta: string, desEncuesta: string, porcentajePonderado: number, ListaEncuestaDetalle: any, listacomborespuesta: any, setListaEncuestaDetalle: any, setCodeRespuesta: any, setDesPregunta: any, setEditingRowEncuesta: any) {

    const objetoEncontrado = listacomborespuesta.find((item: any) => item.Id === codeRespuesta);
    const desOpcionRespuesta = objetoEncontrado ? objetoEncontrado.Texto : null;
    const parentId = (codeGrupo != null && codeGrupo != "") ? codeGrupo : (codeSeccion != null && codeSeccion != "") ? codeSeccion : null

    const ultimoNivel = (codeGrupo != null && codeGrupo != "") ? true : false

    console.log("ParentId es:" + parentId)
    console.log("Editar fila:" + editingRow)

    if (editingRow != null) {

        const editarEnNivel = (elementos: any) =>
            elementos.map((elemento: any) =>
                elemento.id === editingRow
                    ? {
                        ...elemento, CodeRespuesta: codeRespuesta,
                        DesEncuesta: desEncuesta,
                        DesOpcionRespuesta: desOpcionRespuesta,
                        PorcentajePonderado: porcentajePonderado,
                    }
                    : { ...elemento, Detalles: editarEnNivel(elemento.Detalles) }
            );
        setListaEncuestaDetalle(editarEnNivel(ListaEncuestaDetalle));


        /*setListaEncuestaDetalle((prevData: any) =>
            prevData.map((item: any) =>
                item.id === editingRow ? { ...item, DesEncuesta: desEncuesta, CodeRespuesta: codeRespuesta, DesOpcionRespuesta: desOpcionRespuesta } : item
            )
        );*/
        setEditingRowEncuesta(null);
        setDesPregunta('');
        setCodeRespuesta('');

    } else {

        /*let objMember: EncuestaDetalle = {
            id: uuidv4(),
            CodeEncuestaDetalle: "",
            CodeRespuesta: codeRespuesta,
            DesEncuesta: desEncuesta,
            DesOpcionRespuesta: desOpcionRespuesta,
            Detalles: []
        }*/

        let objMember = {
            id: uuidv4(),
            CodeEncuestaDetalle: "",
            CodeRespuesta: codeRespuesta,
            DesEncuesta: desEncuesta,
            DesOpcionRespuesta: desOpcionRespuesta,
            PorcentajePonderado: porcentajePonderado,
            Detalles: []
        }



        if (parentId === null) {
            // Agregar al primer nivel (raíz)
            //setListaPregunta([...ListaPregunta, objMember]);
            setListaEncuestaDetalle((prevData: any) => [...prevData, objMember]);
        } else {
            const agregarEnNivel = (elementos: any) =>
                //if (elementos != undefined) {
                elementos.map((elemento: any) =>
                    elemento.id === parentId
                        ? {
                            ...elemento,
                            Detalles: [...elemento.Detalles, objMember],
                        }
                        : {
                            ...elemento,
                            Detalles: agregarEnNivel(elemento.Detalles),
                        }
                );

            //}


            var newLista = agregarEnNivel(ListaEncuestaDetalle)
            setListaEncuestaDetalle(newLista)
        }


        // Actualiza la lista de datos con el nuevo elemento agregado
        //setListaPregunta((prevData: any) => [...prevData, objMember]);
        setEditingRowEncuesta(null);
        setDesPregunta('');
        setCodeRespuesta('');

    }


    /*var desOpcionRespuesta = ""
    listacomborespuesta.forEach((item: any) => {
        
        if (item.Id == codeRespuesta) {
            desOpcionRespuesta = item.Texto
            console.log(item)
            return
        }
    });

    var existe = false
    ListaPregunta.forEach((item: any) => {
        if (item.DesPregunta == desEncuesta) {
            console.log(item)
            existe = true
            return
        }
    });

    console.log("Existe?: " + existe)

    if (!existe) {
        var objMember = {
            CodeRespuesta: codeRespuesta,
            DesEncuesta: desEncuesta,
            DesOpcionRespuesta: desOpcionRespuesta
        }
        var newListaPregunta = ListaPregunta.map((item: any) => { return item })
        newListaPregunta.push(objMember)
        setListaPregunta(newListaPregunta)
        setCodeRespuesta("")
        setDesPregunta("")
    } else {
        //CUANDO EL ITEM YA EXISTE
    }*/
}

export function addMemberFoto(editingRow: any, desFoto: string, setListaEncuestaFoto: any, setDesFoto: any, setEditingRowEncuestaFoto: any) {


    if (editingRow != null) {
        setListaEncuestaFoto((prevData: any) =>
            prevData.map((item: any) =>
                item.id === editingRow ? { ...item, DesFoto: desFoto } : item
            )
        );
        setEditingRowEncuestaFoto(null);
        setDesFoto('');
    } else {
        var objMember = {
            id: uuidv4(),
            CodeEncuestaFoto: "",
            DesFoto: desFoto,
        }
        setListaEncuestaFoto((prevData: any) => [...prevData, objMember]);
        setEditingRowEncuestaFoto(null);
        setDesFoto('');
    }

    /*var existe = false
    ListaEncuestaFoto.forEach((item: any) => {
        if (item.DesFoto == desFoto) {
            console.log(item)
            existe = true
            return
        }
    });

    console.log("Existe?: " + existe)

    if (!existe) {
        var objMember = {
            DesFoto: desFoto
        }
        var newListaPregunta = ListaEncuestaFoto.map((item: any) => { return item })
        newListaPregunta.push(objMember)
        setListaEncuestaFoto(newListaPregunta)
        setDesFoto("")
    } else {
        //CUANDO EL ITEM YA EXISTE
    }*/
}


export function toQuestionEliminarMiembro(row: any, ListaPregunta: any, setListaPregunta: any) {
    var indice = -1
    ListaPregunta.forEach((item: any, index: number) => {
        if (item.CodePreguntaDetalle == row.CodePreguntaDetalle) {
            console.log(item)
            indice = index
            return
        }
    });
    if (indice > -1) {
        ListaPregunta.splice(indice, 1);
        var newListaPregunta = ListaPregunta.map((item: any) => { return item })
        setListaPregunta(newListaPregunta)
    }
}

export async function getListaComboPregunta(setListaComboMiembroEquipo: any) {
    var dataResponse = await Repository.getListaComboPregunta()
    setListaComboMiembroEquipo(dataResponse)
}

export async function getListaComboTipoTienda(setListaComboTipoTienda: any) {
    var dataResponse = await Repository.getListaComboTipoTienda()
    setListaComboTipoTienda(dataResponse)
}