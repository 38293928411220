import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
type Respuesta = {
  IdRespuesta: string,
  Nombre: string,
};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<Respuesta>[]>(
    () => [
      {
        accessorKey: 'IdRespuesta',
        header: 'ID',
        size: 10,
      },
      {
        accessorKey: 'DesOpcionRespuesta',
        header: 'Nombre Opcion ',
        size: 800,
      },

    ],
    [],
  );
  ;
  return columns
}

type Opcion = {
  CodeRespuestaDetalle: string,
  DesRespuesta: string,
  ValorRespuesta: number,
};
export function GetTColumns() {
  const columns = useMemo<MRT_ColumnDef<Opcion>[]>(
    () => [
      {
        accessorKey: 'DesRespuesta',
        header: 'Descripcion Opcion',
        size: 200,
      },
      {
        accessorKey: 'ValorRespuesta',
        header: 'Valor',
        size: 100,
      },
    ],
    [],
  );
  ;
  return columns
}
export async function getData(FilterDesOpcionRespuesta: string) {

  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_RESPUESTA + "/" + FilterDesOpcionRespuesta, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function getByCode(code: string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_RESPUESTA_BYCODE + "/" + code, "")
  return dataResponse.Data;
}
export async function save(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INSERTAR_RESPUESTA, "", objSave)
  if (dataResponse != null) {
    return dataResponse;
  } else {
    return []
  }
}
export async function update(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.UPDATE_RESPUESTA, "", objSave)
  if (dataResponse != null) {
    return dataResponse;
  } else {
    return null
  }
}
export async function inactiva(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INACTIVO_RESPUESTA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function activa(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.ACTIVO_RESPUESTA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}

export async function doExport(FilterNombreCliente: string) {
  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_CLIENTE_EXCEL + "/" + FilterNombreCliente, 'DescargarExcelCliente.xlsx')

}