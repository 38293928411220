import ContentHeader from "../layout/ContentHeader";
import { useParams, useNavigate } from "react-router-dom";
import * as Controller from "../../../controllers/auditoria-controller";
import React from "react";
import SelectSimple from "../layout/SelectSimple";
import Textbox from "../layout/Textbox";
import Label from "../layout/Label";
import moment from 'moment';

export default function DetalleAuditoria() {
  const { code } = useParams();
  const router = useNavigate();
  const [auditoria, setAuditoria] = React.useState<any>({});
  const [ListaDetalleAuditoria, setListaDetalleAuditoria] = React.useState([]);

  React.useEffect(() => {
    if (code != "") {

      Controller.getListaAuditoriaDetalleByCode(code, setAuditoria, setListaDetalleAuditoria);
    }
  }, []);

  function FormatoPorcentaje(numero: number, digits: number = 1) {
    const result = new Intl.NumberFormat('es-PE', {
      style: 'percent',
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    }).format(numero);
    return result;
  }

  function FormatoDecimal(numero: number, digits: number = 1) {
    const result = new Intl.NumberFormat('es-PE', {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    }).format(numero);
    return result;
  }


  return (
    <div className="content-wrapper">
      <ContentHeader
        title={"Detalle auditoría"}
        homePath={"Home"}
        currentPath={"Detalle auditoría"}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{"Detalle auditoría"}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">

                  <div className="row">
                    <div className="col-md-6">
                      <div className="row mt-1">
                        <div className="col-md-4">
                          <label>Tienda</label>
                        </div>
                        <div className="col-md-8">
                          {auditoria.NombreTienda}
                        </div>
                      </div>

                      <div className="row mt-1">
                        <div className="col-md-4">
                          <label>Usuario registra</label>
                        </div>
                        <div className="col-md-8">
                          {auditoria.NombreCompleto}
                        </div>
                      </div>

                      <div className="row mt-1">
                        <div className="col-md-4">
                          <label>Fecha hora registro</label>
                        </div>
                        <div className="col-md-8">
                          {moment(auditoria.FechaHoraRegistro).format("DD/MM/YYYY HH:mm:ss")}
                        </div>
                      </div>





                      {ListaDetalleAuditoria.length > 0 ?
                        <div>
                          {ListaDetalleAuditoria.map((item: any, index: number) => (
                            <div className="row" key={item.Id}>
                              <div className="col-md-4">
                                <label>{item.DesEncuesta.replace("SECCION DE", "")}</label>
                              </div>
                              <div className="col-md-8">
                                {FormatoDecimal(item.ValPonderado)}
                              </div>
                            </div>
                          ))}
                        </div>
                        : <></>
                      }

                      <div className="row mt-1">
                        <div className="col-md-4">
                          <label>NOTA TOTAL</label>
                        </div>
                        <div className="col-md-8">
                          {auditoria.Nota} / {auditoria.NotaMaxima} <span> ({FormatoPorcentaje(auditoria.Nota / auditoria.NotaMaxima, 2)})</span>
                        </div>
                      </div>


                    </div>
                  </div>
                  {ListaDetalleAuditoria.length > 0 ?
                    <div>
                      {ListaDetalleAuditoria.map((item: any, index: number) => (
                        <div className="row" key={item.Id}>
                          <div className="col-md-8">
                            <div className="card bg-light mb-3">
                              <div className="card-header">
                                <div className="row">
                                  <div className="col-md-10">
                                    <b>{index + 1}</b> {item.DesEncuesta}
                                  </div>
                                  <div className="col-md-2">
                                    {FormatoDecimal(item.ValPonderado)}
                                    <span className="porcentaje-auditoria">({FormatoPorcentaje(item.PorcentajePonderado)})</span>
                                  </div>

                                </div>


                              </div>
                              <div className="card-body">
                                {item.Detalles && item.Detalles.length > 0 && (
                                  <>
                                    {item.Detalles.map((detalle: any, detalleIndex: number) => (
                                      <div key={detalle.Id || detalleIndex}>
                                        <div className="row bg-white mb-3 pt-2 pb-2">
                                          <div className="col-md-10">
                                            <p className="card-text"><b>{detalle.DesEncuesta}</b></p>
                                          </div>
                                          <div className="col-md-2">
                                            <p className="card-text">
                                              <b> {FormatoDecimal(detalle.ValPonderado)}
                                                <span className="porcentaje-auditoria">({FormatoPorcentaje(detalle.PorcentajePonderado)})</span>
                                              </b>
                                            </p>
                                          </div>
                                        </div>

                                        {detalle.Detalles && detalle.Detalles.length > 0 && (
                                          <div className="row">
                                            <div className="col-md-11">
                                              <table className="table" cellSpacing={4} cellPadding={2}>
                                                {detalle.Detalles.map((subDetalle: any, subDetalleIndex: number) => (
                                                  <>
                                                    <tr key={subDetalle.Id || subDetalleIndex}>
                                                      <td valign="top" style={{ width: 30, textAlign: "center" }}>
                                                        {subDetalleIndex + 1}
                                                      </td>
                                                      <td>
                                                        {subDetalle.DesEncuesta}
                                                      </td>
                                                      <td style={{ width: 40 }}>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                      </td>
                                                      <td>
                                                        Respuesta: {subDetalle.DesRespuesta}
                                                      </td>
                                                      <td valign="top" style={{ width: 40, textAlign: "right" }}>
                                                        {subDetalle.ValPonderado}
                                                      </td>
                                                    </tr>

                                                  </>
                                                ))}
                                              </table>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>


                      ))}
                    </div> :
                    <></>
                  }
                  <div className="row" >
                    <div className="col-md-8">
                      <div className="card bg-light mb-3">
                        <div className="card-header">
                          <div className="row">
                            <div className="col-md-10">
                              Comentarios
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          {auditoria.Comentario}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
