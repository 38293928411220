"use client";

import ContentHeader from "../layout/ContentHeader";
import { useParams, useNavigate } from "react-router-dom";
import * as Controller from "../../../controllers/respuesta-controller";
import React from "react";
import Textbox from "../layout/Textbox";
import * as Repository from "../../../repositories/respuesta-repository";
import MaterialReactTable from "material-react-table";
import { IconButton } from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
export default function NuevoRespuesta() {
  const { code } = useParams();
  const Router = useNavigate();
  const [codeRespuesta, setCodeRespuesta] = React.useState("");
  const [ListaRespuesta, setListaRespuesta] = React.useState([]);
  const [desopcionRespuesta, setDesOpcionRespuesta] = React.useState("");
  const [valDesOpcionRespuesta, setValDesOpcionRespuesta] =
    React.useState(false);
  const [valorOpcion, setValorOpcion] = React.useState(0);
  const [desRespuesta, setDesRespuesta] = React.useState("");
  const [editingRow, setEditingRow] = React.useState("");
  const [valdesRespuesta, setValDesRespuesta] = React.useState(false);
  const [tituloVentana, setTituloVentana] = React.useState("Nuevo Respuesta");
  const [isSubmit, setIsSubmit] = React.useState(false);
  React.useEffect(() => {
    if (code != "nuevo") {
      setTituloVentana("Editar Respuesta");
      setCodeRespuesta(code!);
      Controller.getByCode(code, setDesOpcionRespuesta, setListaRespuesta);
    }
  }, []);
  function validarCampos() {
    var Result = true;

    setIsSubmit(true);
    if (desopcionRespuesta == "" || desopcionRespuesta == null) {
      Result = false;
      setValDesOpcionRespuesta(false);
    } else {
      setValDesOpcionRespuesta(true);
    }
    if (Result) {
      Controller.save(
        Router,
        codeRespuesta,
        desopcionRespuesta,
        ListaRespuesta
      );
    } else {
      Controller.Mensaje();
    }
  }

  const handleValorOpcion = (e: any) => {
    let inputValue = e.target.value;

    if (inputValue === "" || (inputValue >= 0 && inputValue <= 100)) {
      setValorOpcion(inputValue);
    }
  };



  return (
    <div className="content-wrapper">
      <ContentHeader
        title={tituloVentana}
        homePath={"Home"}
        currentPath={tituloVentana}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(Router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{tituloVentana}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <label>Nombre Opcion</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={desopcionRespuesta}
                          valueError={valDesOpcionRespuesta}
                          setValue={setDesOpcionRespuesta}
                          setValueError={setValDesOpcionRespuesta}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <label>Opcion</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={desRespuesta}
                          valueError={valdesRespuesta}
                          setValue={setDesRespuesta}
                          setValueError={setValDesRespuesta}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-2">
                      <label>Valor opción</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          id="percentage"
                          type="number"
                          value={valorOpcion}
                          onChange={handleValorOpcion}
                          className="form-control-text"
                          min="0"
                          max="100"
                          placeholder="%"
                        />
                      </div>
                    </div>
                    <div className="col-lg-1">
                      <button
                        className="btn btn-info"
                        onClick={() =>
                          Controller.addMember(
                            desRespuesta,
                            valorOpcion,
                            editingRow,
                            ListaRespuesta,
                            setListaRespuesta,
                            setDesRespuesta,
                            setEditingRow
                          )
                        }
                      >
                        Agregar
                      </button>
                    </div>
                  </div>



                  <div className="row">
                    <div className="col-md-6">
                      <MaterialReactTable
                        columns={Repository.GetTColumns()}
                        data={ListaRespuesta}
                        enableRowActions
                        enablePagination={false}
                        enableTopToolbar={false}
                        enableBottomToolbar={false}
                        renderRowActions={({ row, table }) => (
                          <div className='row'>
                            <div className="col-lg-6">
                              <IconButton
                                onClick={() =>
                                  Controller.toQuestionEliminarOpcion(
                                    row.original,
                                    ListaRespuesta,
                                    setListaRespuesta
                                  )
                                }
                              >
                                <DeleteIcon titleAccess="Eliminar" />
                              </IconButton>
                            </div>
                            <div className="col-lg-6">
                              <IconButton
                                onClick={() => {
                                  setEditingRow(row.original.CodeRespuestaDetalle)
                                  setDesRespuesta(row.original.DesRespuesta)
                                  setValorOpcion(row.original.ValorRespuesta)
                                }}
                              >
                                <EditIcon titleAccess="Editar" />
                              </IconButton>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <button
                          className="btn btn-default"
                          onClick={() => Controller.toConsulta(Router)}
                        >
                          Cancelar
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => validarCampos()}
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
